<template>
  <div>
    <h3 class="flix-html-h3">
      {{ $t("message.maxApplicants") }}
    </h3>
    <div class="flix-form-group">
      <div class="flix-row">
        <div class="flix-col-md-4" v-for="(event, index) in data.events" :key="index">
          <h4 class="flix-html-label">{{ $tc('message.appointments', 1) }} {{ index + 1 }}<br>{{ $beautifyDate(event) }}</h4>
          <select :key="max_applicants.length" class="flix-form-control flix-form-group" v-model="max_applicants[index]">
            <option v-for="index in Array(50).keys()" :key="index + 1" :value="index + 1">{{ index + 1 }} Veranstaltungs-Besucher</option>
            <option v-for="index in Array(4).keys()" :key="((1 + index) * 10) + 50" :value="((1 + index) * 10) + 50">{{ ((1 + index) * 10) + 50 }} Veranstaltungs-Besucher</option>
            <option v-for="index in Array(10).keys()" :key="(1 + index) * 100" :value="(1 + index) * 100">{{ (1 + index) * 100 }} Veranstaltungs-Besucher</option>
            <option v-for="index in Array(8).keys()" :key="(1 + index) * 500 + 1000" :value="(1 + index) * 500 + 1000">{{ (1 + index) * 500 + 1000 }} Veranstaltungs-Besucher</option>
          </select>
        </div>
      </div>
    </div>
    <div class="flix-row" v-if="checkUser()">
      <div class="flix-col-md-4">
        <webinar :data="data" :callback="function(webinar) { setWebinar(webinar) }" />
      </div>
    </div>
    <div class="flix-form-group">
      <a href="#" class="flix-btn flix-btn-success" @click.prevent="saveData()">{{ $t('message.save', { name: '' }) }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    webinar () { return import('@/components/assistent/components/eventpicker/webinar') }
  },
  props: { data: Object, callback: Function },
  data () {
    return {
      max_applicants: [],
      webinar: this.data.webinar
    }
  },
  methods: {
    checkUser () {
      var i = 1
      if (i === 1) {
        return true
      }
      var username = this.$getUserVariables().user.username
      if (username === 'admin' || username === 'vorbild') {
        return true
      }
      return false
    },
    setWebinar (webinar) {
      this.webinar = webinar
    },
    saveData () {
      var data = { max_applicants: this.max_applicants, webinar: this.webinar }
      this.callback(data)
    },
    getMaxApplicants () {
      var key = 0
      Object.keys(JSON.parse(JSON.stringify(this.data.events))).forEach(function (k) {
        if (typeof this.data.max_applicants === 'undefined' || typeof this.data.max_applicants[key] === 'undefined' || !this.data.max_applicants[key]) {
          this.max_applicants.push(5)
        } else {
          this.max_applicants.push(this.data.max_applicants[key])
        }
        key++
      }.bind(this))
    }
  },
  mounted () {
    this.getMaxApplicants()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
